<template>
    <v-container>
      <v-row justify="center" class="mt-10">
        <v-img alt="Familytablet"
        src='../../assets/logo.png'
        max-width="200"
        ></v-img>
      </v-row>
      <v-row justify="center" class="mt-10">
        <v-form @submit.prevent="userLogin">
          <v-item-group>
              <v-text-field
                nput type="email"
                class="form-control form-control-lg"
                v-model="userForm.email"
                placeholder="Emailadresse"
                height="60"
              />
          </v-item-group>

          <v-item-group>
              <v-text-field
                type="password"
                class="form-control form-control-lg"
                v-model="userForm.password"
                placeholder="Passwort"
                height="60"
              />
          </v-item-group>

          <v-btn type="submit" large color="#013333" outlined class="mt-10 ml-3">
            <v-icon>
              mdi-account
            </v-icon>
            Einloggen
          </v-btn>
        </v-form>
      </v-row>
    </v-container>
</template>

<script>
import { FirebaseAuth } from '../../firebase'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      userForm: {
        email: '',
        password: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  mounted () {
    this.CheckLoginState()
    console.log(process.env)
  },
  methods: {
    CheckLoginState () {
      if (this.user.loggedIn) {
        this.$router.push('/mobile')
      }
    },
    userLogin () {
      FirebaseAuth
        .signInWithEmailAndPassword(this.userForm.email, this.userForm.password)
        .then(() => {
          this.$router.push('/mobile')
        })
        .catch((error) => {
          alert(error.message)
        })
    }
  }
}
</script>
